@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@font-face {
  font-family: 'CustomFont';
  src: url('./fonts/UTM.ttf') format('truetype'),
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222831;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

.avatar{
  width: 154px;
  height: 154px;
  position: absolute;
  top: 55.2px;
  right: 40px;
  border-radius: 500px;
}

.name{
  font-family: 'CustomFont', sans-serif;
  color: rgb(255, 255, 255);
  width: 223px;
  right: inherit;
  position: absolute;
  right: 6px;
  text-align: center;
  display: flex;
  justify-content: center;
  top: 199px;
  font-size: 24px;
  height: 36px;
  background-size: cover;
  font-weight: 400;
  text-shadow: #222222 0px 1px 1px;
}

.box-content{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  
}

.box-update{
  background-color: rgb(242, 242, 242);
  width: 300px;
  height: 400px;
  padding: 20px;
  border-radius: 10px;
}

.title-upload {
  margin-bottom: 10px;
}

.uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar-container {
  margin-bottom: 20px;
}

.avatar-real{
  width: 540px;
  height: 540px;
  position: absolute;
  top: 192px;
  right: 137px;
  border-radius: 429px;
}

.name-real{
  font-family: 'CustomFont', sans-serif;
  color: rgb(255, 255, 255);
  top: 697px;
  font-size: 83px;
  text-align: center;
  position: absolute;
  right: 25px;
  height: 127px;
  background-size: cover;
  font-weight: 400;
  width: 765px;
  text-shadow: #222222 0px 1px 1px;
  /* background: red; */

}

.file-input {
  display: none; /* Ẩn input gốc */
}

.file-label {
  display: inline-block;
  background-color: #6200ea;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.file-label:hover {
  background-color: #3700b3;
}

.file-label:active {
  background-color: #23036a;
}

.name-input {
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
  box-sizing: border-box;
}

.buttonExport {
  width: 9em;
  height: 3em;
  border-radius: 30em;
  font-size: 15px;
  font-family: inherit;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  box-shadow: 6px 6px 12px #c5c5c5,
              -6px -6px 12px #ffffff;
 }
 
 .buttonExport::before {
  content: '';
  width: 0;
  height: 3em;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #0fd850 0%, #f9f047 100%);
  transition: .5s ease;
  display: block;
  z-index: -1;
 }
 
 button:hover::before {
  width: 9em;
 }

 .box-btn{
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
 }