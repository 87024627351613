.box-form{
    padding: 30px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

.header-text {
    font-size: 30px;
    color: rgb(0, 130, 0);
    margin-bottom: 10px;
}